aside
    position: fixed
    width: 170px
    padding-top: 50px
    padding-left: 20px
    color: #6c757d
    font-size: 14px
    div
        margin-bottom: 20px
        &:hover
            cursor: pointer
            color: whitesmoke


@media (max-width: 767px) //900
    aside
        display: none