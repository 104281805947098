.method
    margin-top: 30px
    width: 60%
    font-size: 16px
    &__paragraph
        text-align: justify
        text-indent: 15px
    &__title
        margin-top: 20px
        margin-bottom: 15px
        text-align: center
    &__img
        margin-top: 15px
        margin-bottom: 15px
        width: 100%
        object-fit: contain
    &__link
        text-decoration: none
        color: whitesmoke
        font-weight: bold
        &:hover
            color: silver
            cursor: pointer
        &:visited
            color: whitesmoke

@media (max-width: 991px)
    .method
        width: 80%
        padding-left: 120px

@media (max-width: 767px)
    .method
        width: 94%
        padding-left: 0