.feedback
    width: 60%
    margin-top: 30px
    text-align: justify
    &__link
        color: whitesmoke
        text-decoration: none
        &:hover
            color: whitesmoke
            font-weight: bold
    &__btns
        margin-top: 20px
    &__btn
        margin-right: 20px

@media (max-width: 991px)
    .feedback
        width: 80%
        padding-left: 120px

@media (max-width: 767px)
    .feedback
        width: 90%
        padding-left: 0