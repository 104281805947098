.profile
    width: 60%
    margin-top: 30px
    &__title
        margin-top: 20px
    &__question
        margin-top: 20px
        margin-bottom: 15px
        font-size: 18px
        font-weight: bold
    &__list
        font-size: 16px

@media ( max-width: 991px )
    .profile
        width: 80%
        padding-left: 120px
        &__list
            width: unset

@media (max-width: 767px)
    .profile
        width: 94%
        padding-left: 0