.edge
    margin-top: 30px
    width: 60%
    font-size: 16px
    &__paragraph
        text-align: justify
        text-indent: 15px
    &__title
        margin-top: 20px
        margin-bottom: 15px
        text-align: center
    // &__img
    //     margin-top: 15px
    //     margin-bottom: 15px
    //     width: 100%
    //     object-fit: contain

@media (max-width: 767px)
    .edge
        width: 90%