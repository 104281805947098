.notes
    width: 60%
    margin-top: 30px
    &__title
        text-align: center
        font-size: 28px
    &__list
        display: flex
        flex-direction: column
        &_item
            margin-bottom: 8px
            font-size: 16px
            span
                cursor: pointer

@media ( max-width: 991px )
    .notes
        width: 80%
        padding-left: 120px

@media (max-width: 767px)
    .notes
        width: 94%
        padding-left: 0