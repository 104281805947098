.manual
    width: 60%
    margin: 30px auto 0
    &__title
        text-align: center
        margin-bottom: 20px
    &__list
        width: 650px
        text-align: justify
        &_item
            &:hover
                text-decoration: underline
                cursor: pointer
    &__link
        color: white
        text-decoration: none
        &:hover
            color: white
            text-decoration: underline
    &__btn
        margin-top: 20px
        margin-left: 30px

@media ( max-width: 991px )
    .manual
        width: 80%
        padding-left: 120px
        &__list
            width: unset

@media (max-width: 767px)
    .manual
        width: 96%
        padding-left: 0