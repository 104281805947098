@import '../../style/style'

.active
    color: white 
    text-decoration: none 
    font-size: 24px
    font-weight: bold
    &:hover
        color: white

#btn-manual
    animation-name: btnbeat
    animation-duration: 1s
    animation-timing-function: ease
    animation-iteration-count: 6  // infinite
    &:hover, &:focus
        animation-play-state: paused

@media ( max-width: 767px )
    .nav-btn
        display: block
        margin-bottom: 5px
        border: none
        margin-left: 0!important