.quote-item
    display: grid
    grid-template-columns: 88% 10%
    column-gap: 2%
    margin-top: 25px
    padding: 10px
    font-size: 18px
    line-height: 35px
    font-style: italic
    background-color: #333333
    border-color: #555555
    &__quote
        text-align: justify
    &__author
        text-align: right
    &__icon
        margin-left: 10px
        cursor: pointer
        &:first-child:hover
            color: #0277bd 
        &:last-child:hover
            color: #e53935 
            
@media (max-width: 991px)
    .list-item
        grid-template-columns: 84% 14%