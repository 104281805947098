.methodology
    width: 60%
    margin-top: 30px
    &__title
        text-align: center
        font-size: 28px
    &__subtitle
        margin-top: 30px
        text-align: center
        font-size: 24px
        cursor: pointer
        display: inline
    &__list
        display: flex
        flex-direction: column
        &_item
            margin-bottom: 8px
            font-size: 16px
            span
                cursor: pointer
    &__img
        margin-top: 20px
        width: 100%
        object-fit: contain

@media ( max-width: 991px )
    .methodology
        width: 80%
        padding-left: 120px
        &__list
            width: unset

@media (max-width: 767px)
    .methodology
        width: 100%
        padding-left: 0
        &__title
            font-size: 22px
        &__subtitle
            font-size: 20px