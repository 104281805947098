.materials
    width: 60%
    margin-top: 30px
    &__wrapper
        display: grid
        grid-template-columns: 40% 55%
        column-gap: 5%
        margin-top: 20px
    &__btns
        display: flex
        flex-direction: column
    &__img
        width: 100%
        height: 500px
        object-fit: contain

@media ( max-width: 991px )
    .materials
        width: 80%
        padding-left: 120px

@media (max-width: 767px)
    .materials
        width: 94%
        padding-left: 0

@media (max-width: 575px)
    .materials
        &__title
            font-size: 1.4em
        &__wrapper
            grid-template-columns: 100%
            gap: 15px
            margin-top: 10px
        &__btns
            display: grid
            grid-template-columns: repeat(auto-fit, 120px)
            gap: 15px
            button
                margin-top: 10px!important
        &__img
            height: unset
