.main
    width: 60%
    margin-top: 40px
    &__quote
        margin-bottom: 40px
        padding: 20px
        font-size: 20px
        border: 1px solid whitesmoke
        border-radius: 15px
        font-style: italic
    &__title
        text-align: center

@media (max-width: 991px)
    .main
        width: 80%

@media (max-width: 575px)
    .main
        width: 90%
        margin-top: 30px
        &__quote
            margin-bottom: 30px
            padding: 15px
            font-size: 18px
        &__title
            font-size: 20px