.list
    width: 70%
    margin: 0 auto
    &__empty
        font-size: 20px

@media ( max-width: 991px )
    .list
        width: 100%
        padding-left: 120px

@media ( max-width: 767px )
    .list
        width: 100%
        padding-left: 0