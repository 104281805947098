.images
    width: 75%
    margin-top: 30px
    display: grid
    grid-template-columns: repeat(auto-fit, 200px)
    gap: 50px
    justify-content: center
    &__wrapper
        position: relative
    &__item
        width: 200px
        height: 200px
        object-fit: cover
        cursor: pointer
        &_large
            width: 100%
            height: 80vh
            object-fit: contain
    &__btn
        width: 90px
        height: 35px
        position: absolute
        bottom: 5px
        left: 55px
        opacity: 80%

@media ( max-width: 1075px )
    .images
        width: 90%
        padding-left: 120px

@media (max-width: 767px)
    .images
        width: 100%
        padding-left: 0