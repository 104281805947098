.quotes
    width: 80%
    margin-top: 30px
    display: flex
    flex-direction: column
    &__title
        text-align: center
    &__btn
        margin: 40px auto 15px
        &_back
            margin-top: 0

@media (max-width: 575px)
    .quotes
        width: 94%