.first
    width: 70%
    &__title
        font-size: 2rem
        font-weight: bold
        text-align: center
    &__text
        font-size: 18px
        text-align: justify
    &__list
        font-size: 18px
    &__btn
        display: block
        margin: 30px auto
        font-size: 18px


@media ( max-width: 1119px )
    .first
        &__text
            font-size: 28px


@media ( max-width: 767px )
    .first
        width: 85%
        &__title
            font-size: 2rem
        &__text
            font-size: 22px
        &__btn
            font-size: 16px
            margin-top: 0


@media ( max-width: 425px )
    .first
        width: 90%
        &__title
            font-size: 1.5rem
            &_small
                font-size: 20px
                text-align: justify
        &__text
            font-size: 20px


@media ( max-width: 319px )
    .first
        width: 95%
        &__text
            font-size: 18px