.item
    display: flex
    flex-direction: column
    align-items: center
    &__btn
        margin: 15px auto
        width: 200px
    
.items-form
    width: 70%
    margin: 0 auto

.filter
    display: flex
    margin: 30px auto
    justify-content: space-between
    width: 70%
    &__btn
        // width: 140px
        height: 40px
        padding-left: 20px
        padding-right: 20px
        background-color: #333333
        border-color: #555555
        border-radius: 15px 
        color: whitesmoke
        &:hover
            background-color: #222222
        &:focus
            background-color: darkgrey
            color: white

@media ( max-width: 1199px )
    .filter
        width: 80%

@media ( max-width: 1099px )
    .filter
        width: 80%
        padding-left: 60px

@media ( max-width: 991px )
    .item
        &__title
            font-size: 1.8em
        &__subtitle
            font-size: 1.5em
    .filter
        width: 100%
        margin-top: 20px
        padding-left: 120px
        display: grid
        grid-template-columns: repeat(auto-fit, 150px)
        gap: 15px
        justify-content: center
    
@media ( max-width: 767px )
    .filter
        padding-left: 0
    .items-form
        width: 100%
    // .filter
    //     width: 100%
    //     margin: 20px auto
    //     display: grid
    //     grid-template-columns: repeat(auto-fit, 150px)
    //     gap: 20px
    //     justify-content: center

@media ( max-width: 425px )
    .item
        &__title
            font-size: 1.5em
            margin-bottom: 10px
        &__subtitle
            font-size: 1.2em