.links
    width: 50%
    margin-top: 30px
    &__list
        list-style: none
        margin-top: 20px
        padding-left: 0
        &_item
            margin-bottom: 10px
            display: flex
            justify-content: space-between
            a
                text-decoration: none
                font-size: 18px
                color: whitesmoke
                &:hover
                    font-weight: bold
    &__btn
        margin: 25px auto 15px
        &_back
            margin-left: 30px

@media (max-width: 767px)
    .links
        width: 80%

@media (max-width: 459px)
    .links
        width: 90%